<!-- 智慧服务/校园生活/一卡通对账 -->
<template>
    <checking-scroll-wrapper ></checking-scroll-wrapper>
</template>
<script>
    import CheckingScrollWrapper from '@/components/scrollWrapper/cardChecking.vue';
    
    export default {
        name: "SServiceStuChecking",
        components: {
            CheckingScrollWrapper
        }
    }
</script>