<template>
    <!-- 智慧服务/学生生活服务/智慧食堂 -->
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SServiceStuFood",
        components: {
            Building
        }
    }
</script>