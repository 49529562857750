<template>
    <!-- 智慧服务/财务管理/薪酬管理-薪酬项目 -->
     <SalaryProjectScrollWrapper></SalaryProjectScrollWrapper>
</template>
<script>
import SalaryProjectScrollWrapper from "@/components/scrollWrapper/SalaryProject";
export default {
    name: "SServiceMoneySalaryProject",
    components: {
        SalaryProjectScrollWrapper,
    }
}
</script>
