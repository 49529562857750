<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2023-11-27 09:46:14
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2023-11-27 09:49:26
 * @FilePath: \school_end\src\views\SServiceStuSetMealOrder.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 套餐订单 -->
    <set-meal-order-scroll-wrapper ></set-meal-order-scroll-wrapper>
</template>
<script>
import SetMealOrderScrollWrapper from '@/components/scrollWrapper/SetMealOrder.vue';

export default {
    name: "SServiceStuSetMealOrder",
    components: {
        SetMealOrderScrollWrapper,
    },

}
</script>
