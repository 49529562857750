<!-- 薪酬管理-薪酬分析 -->
<template>
    <SServiceMoneySalaryScrollWrapper></SServiceMoneySalaryScrollWrapper>
  </template>
  <script>
  import SServiceMoneySalaryScrollWrapper from "@/components/scrollWrapper/SServiceMoneySalary";
  export default {
    name: "SServiceMoneySalaryAnalysis",
    components: {
      SServiceMoneySalaryScrollWrapper,
    },
  };
  </script>
