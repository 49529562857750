<template>
    <div>
      <!--智慧服务 - 校园生活 - 托管服务-->
      <KeepAliveRouterView />
    </div>
  </template>
  <script>
  export default {
    name: "SServiceStuDeposit"
  }
  </script>
