<template>
    <!-- 托管服务-托管学生 -->
    <trusteeship-student-scroll-wrapper ></trusteeship-student-scroll-wrapper>
</template>
<script>
import TrusteeshipStudentScrollWrapper from '@/components/scrollWrapper/TrusteeshipStudent.vue';

export default {
    name: "SServiceStuDepositStudent",
    components: {
        TrusteeshipStudentScrollWrapper,
    },

}
</script>
