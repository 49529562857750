<template>
    <!-- 智慧服务/智慧校园服务/报修系统 -->
    <SServiceSchReportScrollWrapper></SServiceSchReportScrollWrapper>
</template>
<script>
import SServiceSchReportScrollWrapper from "@/components/scrollWrapper/SServiceSchReport";
export default {
    name: "SServiceSchReport",
    components: {
        SServiceSchReportScrollWrapper
    }
}
</script>
