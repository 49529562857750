<template>
    <!-- 托管服务-核查记录 -->
    <check-record-scroll-wrapper ></check-record-scroll-wrapper>
</template>
<script>
import CheckRecordScrollWrapper from '@/components/scrollWrapper/CheckRecord.vue';
export default {
    name: "SServiceStuCheckRecord",
    components: {
        CheckRecordScrollWrapper,
    },
}
</script>
