<template>
    <!-- 托管服务-托管账单 -->
    <trusteeship-bill-scroll-wrapper ></trusteeship-bill-scroll-wrapper>
</template>
<script>
import TrusteeshipBillScrollWrapper from '@/components/scrollWrapper/TrusteeshipBill.vue';

export default {
    name: "SServiceStuDepositBill",
    components: {
        TrusteeshipBillScrollWrapper,
    },

}
</script>
