<!-- 智慧服务/智慧家长服务 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SServiceParent",
        components: {
            Building
        }
    }
</script>