<!-- 薪酬管理-薪酬查询 -->
<template>
    <SServiceMoneySalaryQueryScrollWrapper></SServiceMoneySalaryQueryScrollWrapper>
  </template>
  <script>
  import SServiceMoneySalaryQueryScrollWrapper from "@/components/scrollWrapper/SServiceMoneySalaryQuery.vue";
  export default {
    name: "SServiceMoneySalaryQuery",
    components: {
        SServiceMoneySalaryQueryScrollWrapper,
    },
  };
  </script>
