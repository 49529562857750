<!--  -->
<template>
    <keep-alive-router-view />
</template>

<script>
    export default {
        name: "SServiceMoneyPay"
    }
</script>
