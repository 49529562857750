
<!-- 智慧服务/财务管理/薪酬管理 -->
<template>
  <KeepAliveRouterView />
</template>
<script>
export default {
    name: "SServiceMoneySalary",

}
</script>
